import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Layout, Sponsors } from '~/components/common';
import { Frontmatter } from '~/config';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Sponsors',
  order: 10,
  screenShoot: false,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      {/* List is scrollable (for mobile) */}
      <div tw="pb-20 [padding-top:15vh] md:(pt-16 grid w-full [min-height:100vh] content-center)">
        <Sponsors delay={0.5} duration={2.5} />
      </div>
    </Layout>
  );
};

export default Page;
